import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Zoom
} from '@mui/material';
import VendorAutocomplete from 'src/components/FormInputs/BrandAutocomplete/VendorAutocomplete';
import React, { useState, useEffect } from 'react';
import CSVParser from 'src/components/CSVcomponent/CSVParser';
import BrandTable from 'src/components/BrandTable';
import { useSnackbar } from 'notistack';

const checkEnum = ['', null, [], undefined, {}];

function BulkUploadModal({
  title,
  modalOpen,
  data,
  loading,
  setData,
  handleSubmit,
  closeModal,
  selectFields,
  requiredFields,
  setShowManual,
  tableConfig,
  showMessage = '',
  disabledSubmit = false
}) {
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const isEmpty = requiredFields?.some((e) => checkEnum.includes(e));
    console.log(isEmpty);
    if (!isEmpty) setShow(true);
    else setShow(false);
  }, [requiredFields]);

  useEffect(() => {
    if (data.length === 0) return;

    const requiredFields = tableConfig
      ?.filter((item) => item.required)
      .map((item) => item?.accessor);

    const submittedFields = Object?.keys(data[0]);

    const validCSV = requiredFields?.every((item) =>
      submittedFields?.includes(item)
    );

    if (!validCSV) {
      enqueueSnackbar('Required fields are not present or Headers are wrong', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }
  }, [data]);

  return (
    <Dialog open={modalOpen} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {showMessage && <Alert severity="warning">{showMessage}</Alert>}
        <div className="content">{selectFields}</div>
        <Collapse in={show}>
          <Button
            onClick={() => {
              setShowManual(true);
            }}
          >
            Show Manual for CSV
          </Button>
          <CSVParser setData={setData} />
        </Collapse>
        <Box component="div" sx={{ mt: '4em' }}>
          {data.length > 0 && (
            <BrandTable tableData={data} tableConfig={tableConfig} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={loading && <CircularProgress size={16} />}
        >
          Submit
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BulkUploadModal;
