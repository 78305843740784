import React, { useEffect, useState } from 'react';
import RTOService from 'src/services/RTO.service';
import BrandAutocomplete, { createOption, createOptionsFromArr } from '.';

// TODO: data will come from api
function StateAutoComplete({
  onChange,
  value,
  name,
  label,
  required = false,
  ...rest
}) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await RTOService.get();
      Object.keys(data)?.forEach((key) => {
        setOptions((prev) => [...prev, { label: data[key]?.name, value: key }]);
      });
    })();
  }, []);

  return (
    <BrandAutocomplete
      label={label}
      placeholder="Select State"
      onChange={onChange}
      required={required}
      value={value}
      name={name ?? 'state'}
      options={options}
      {...rest}
    />
  );
}

export default StateAutoComplete;
